import React from "react";
import ListType2b from "../../contents/ListType2b";

export default function PageSub05b1() {
  return (
    <div id="pageSub05b1">
      <ListType2b></ListType2b>
    </div>
  );
}
