import React from "react";
import ListType1b from "../../contents/ListType1b";

export default function PageSub01b4() {
  return (
    <div id="pageSub01b4">
      <ListType1b></ListType1b>
    </div>
  );
}
