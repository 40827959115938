import React from "react";
import MentorApplicationAdd from "../../contents/Mentoring/MentorApplicationAdd";

export default function PageSub06d2() {
  return (
    <div id="pageSub06d2">
      <MentorApplicationAdd/>
    </div>
  );
}
