import React from 'react'
import '../css/common-s.css';
import '../css/style-s.css';

export function PrivacyPolicy() {
    return (
        <>
            <div className="policy-title">
                <h4>DID기술융합공작소 사이트 개인정보 수집에 대한 동의 내용</h4>
            </div>

            <div className="term-textbox mt-10">
                <div className="tb-join">
                    <table>
                        <thead>
                            <tr>
                                <th>필수항목</th>
                                <th>수집 및 이용목적</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>암호화된 동일인 식별정보 (CI), 또는 I-pin제공정보, 이름, 로그인ID,
                                    비밀번호, 휴대전화, 이메일, 생년월일, 성별, 면허번호, <span className="col-red">소속기관명, 근무부서명, 직장전화번호</span>
                                </td>
                                <td>회원제 서비스 이용에 따른 본인확인, 개인 식별, 만 14세 미만 아동
                                개인정보 수집 시 법정 대리인 동의여부 확인, 불만처리 등 민원처리,
                                인터넷 교육 신청 및 곰모 참여 서비스
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p className="mt-10 ref">* 필수항목에 대한 수집ㆍ이용에 동의 하지 않을 권리가 있으나, 미 동의시 회원가입 서비스를 이용할 수 없으니 참고하시기 바랍니다.</p>
            </div>

            <div className="policy-articles mt-10">
                <h5 className="p-chapter"><strong>1. 개인정보 수집 및 이용에 대한 안내</strong></h5>
                <p>정보통신망법 규정에 따라 서비스에 회원가입 신청하시는 분께 수집하는 개인정보의 항목, 개인정보의 수집 및 이용목적, 개인정보의 보유 및 이용기간을 안내 드리오니 자세히 읽은 후 동의하여 주시기 바랍니다.</p>
                <ul className="basic">
                    <li>1. 수집하는 개인정보
                        <p>이용자는 회원가입을 하는 경우에만 서비스를 이용할 수 있으며 서비스를 이용하기 위해 회원가입을 할 경우, 당사는 서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다.</p>
                        <p className="mt-20">회원가입 시점에 당사가 이용자로부터 수집하는 개인정보는 아래와 같습니다. <br />회원 가입 시에 ‘성명, 이메일 주소, 연락처, 생년월일, 거주지 주소, 소속’을필수항목으로 수집합니다. </p>
                        <p className="mt-20">서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기정보, 위치정보가 생성되어 수집될 수 있습니다. 구체적으로</p>
                        <ul className="basic">
                            <li>1) 서비스 이용 과정에서 이용자에 관한 정보를 정보통신서비스 제공자가 자동화된 방법으로 생성하여 이를 저장(수집)하거나,</li>
                            <li>2) 이용자 기기의 고유한 정보를 원래의 값을 확인하지 못하도록 안전하게 변환한 후에 수집하는 경우를 의미합니다.</li>
                        </ul>
                    </li>
                    <li className="mt-20">2. 수집한 개인정보의 이용
                        <p>서비스(모바일 웹/앱 포함)의 회원관리, 서비스 개발・제공 및 향상, 안전한 인터넷 이용환경 구축 등 아래의 목적으로만 개인정보를 이용합니다.</p>
                        <ul className="li-dash mt-10"><li>회원 가입 의사의 확인, 이용자 식별 등 회원관리를 위하여 개인정보를 이용합니다.</li>
                            <li>서비스 방문 및 이용기록의 분석과 신규 서비스 요소의 발굴 및 기존 서비스 개선 등을 위하여 개인정보를 이용합니다.</li>
                            <li>법령 및 당사 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정도용 및 부정거래
                                방지, 약관 개정 등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다.</li>
                            <li>상품 및 서비스의 배송을 위하여 개인정보를 이용합니다.</li>
                            <li>이벤트 정보 및 참여기회 제공, 마케팅 및 프로모션 목적으로 개인정보를 이용합니다.</li>
                            <li>서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등에 개인정보를 이용합니다.</li>
                            <li>보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축을 위해 개인정보를 이용합니다.</li>
                        </ul></li>
                    <li>3. 개인정보의 파기
                        <ul className="basic"><li>개인정보의 수집 및 이용목적이 달성된 개인정보는 재생이 불가능한 방법으로 파기하고 있습니다.</li>
                            <li>법령에서 보존의무를 부과한 정보에 대해서도 해당 기간 경과 후 지체없이 재생이 불가능한 방법으로 파기합니다.</li>
                            <li>전자적 파일 형태의 경우 복구 및 재생이 되지 않도록 기술적인 방법을 이용하여 안전하게 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식 등으로 파기합니다.</li>
                        </ul></li>
                </ul>
                <h5 className="p-chapter"><strong>2. 초상권 수집 및 이용에 대한 안내</strong></h5>
                <p>【초상권 사용 동의서】</p>
                <p className="mt-20">정보화 사회로 홈페이지 및 블로그, SNS 등 다양한 정보를 공유함에 따라 개인정보 보호 정책으로 &lt;DID기술융합공작소&gt; 활동시 촬영된 사진 및 동영상에 관하여 초상권에 대한 동의를 받고자 합니다.</p>
                <ul className="basic mt-10"><li>▣ 사용 기관 : DID기술융합공작소, 따뜻한메이커연구소 주식회사</li>
                    <li>▣ 사용 매체명 : 소셜미디어매체, 웹사이트, 홍보물, 기사, 보고서</li>
                    <li>▣ 사용 형태 : DID기술융합공작소 교육 또는 행사 중 찍은 사진 또는 동영상 자료</li>
                    <li>▣ 사용 목적 : 내 외부 홍보 및 내부 보고용</li>
                    <li>▣ 초상권 사용료 : 없음</li>
                </ul><p className="mt-20">위의 사항에 따라 초상권 사용에 동의하며, 그 권리를 위 기관에 위임합니다. </p>
            </div>
        </>
    );
}


export default function () {
    return (
        <div id="wrap" className='wrap privacy'>
            <div className="content_wrap">
                <div className="inner_wrap">
                    <div className="box1">
                        <div className="policy">
                            <PrivacyPolicy /> 
                        </div>
                   </div>
                </div>
            </div>
        </div>
    )
}
