import React from "react";
import SubSideMenu from "../../contents/SubSideMenu";
export default function PageSub01b1() {
  return (
   
    <div id="pageSub01b1" className="hello_wrap">
   
      <div className="hello_text">
        <p>
        지금은 4차 산업이 무한한 미래를 이끌어 가고 있는 시대입니다.
        여러 분야에서 새로운 아이디어와 컨텐츠를 통해 비즈니스가 형성되고, 다양한 사업과 창업이 이루어지고 있습니다.
        이러한 가운데 아이디어를 사업화하거나 창업을 원하는 분들로부터 전문 메이커의 고도화된 창작활동 지원과 이를 기반으로 한 제조기술 혁신 및 스마트 제조 창업의 필요성이 제기되어 왔습니다.
        </p>
        <p>
        급격히 변화하는 시대에 발맞춰 예비 창업자나 기업, 메이커스페이스 운영자 그리고 일반 메이커들까지 멘토링과 장비 지원을 받아 다양한 창작활동을 하고, 이를 바탕으로 사업화까지 나갈 수 있도록 돕는 전문랩이 조성되었습니다.
        </p>
        <p>
        DID기술융합공작소는 대전시의 공간 지원과 대덕연구단지의 기술력, 대전창조경제혁신센터의 창업전문가 컨설팅, 따뜻한메이커연구소의 운영 노하우 등을 바탕으로 기업·창업자·메이커들에게 멘토링과 컨설팅을 지원하고, 메이킹 장비를 활용하여 창업과 사업화를 이룰 수 있도록 One-Stop 서비스를 제공하는 전문 메이커 스페이스입니다.
        </p>
        <p>
        사업화 및 창업 시의 시행착오 최소화,최적의 제품생산을 위한 문제해결, 그리고 더 나은 제품 제작을 위한 기술 혁신 및 노하우 축적이 가능하도록 대전전문랩이 적극적으로 도와드리겠습니다.
        </p>
        <p>
        앞으로 DID기술융합공작소는 대전·충청·세종 지역 메이커 문화의 중추적인 역할을 담당하게 될 것입니다. 다양한 아이디어가 사업화와 창업으로 이루어지길 기대합니다. 아울러 이곳을 통해 다양한 메이커 커뮤니티가 형성되고, 보다 나은 메이커 문화의 확산이 이루어질 것으로 믿습니다. 메이커와 메이커스페이스의 연계로 기술혁신을 이루어 미래 기술 발전과 지역 및 국가의 경제 발전에 기여하게 되길 희망합니다.
        </p>
        <div className="extra">
          <p>2022년 2월</p>
          <p>대전전문랩, DID융합기술공작소 사업총괄책임자</p>
          {/* <p><img src="/images/greetings_sign.png"/></p> */}
        </div>
      </div>
      <div className="hello_image">
        <div className="image_part"><img src="/images/greetings_bg.png"/></div>
      </div>
    
    </div>
   
  );
}
