import React from "react";
import ListType2c from "../../contents/ListType2c";

export default function PageSub07b1() {
  return (
    <div id="pageSub07b1">
      <ListType2c></ListType2c>
    </div>
  );
}
