import React from "react";
import TableType2c from "../../contents/TableType2c";
import TextExtraType1c from "../../contents/TextExtraType1c";

export default function PageSub02b1() {
  return (
    <div id="pageSub02b1">
      <TextExtraType1c></TextExtraType1c>
      <TableType2c></TableType2c>
    </div>
  );
}
