import React from "react";

export default function TableInfoType1b() {
  return (
    <div className="table_info table_info_type1 table_info_type1b">
      <div className="info_1">예약가능</div>
      <div className="info_3">예약불가</div>
      <div className="info_2">선택일</div>
    </div>
  );
}
