import React from "react";
import TableType5b from "../../contents/TableType5b";

export default function PageSub06d3() {
  return (
    <div id="pageSub06d3">
      <TableType5b></TableType5b>
    </div>
  );
}
