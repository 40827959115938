import React from "react";
import TextExtraType1d from "../../contents/TextExtraType1d";
import ListType1c from "../../contents/ListType1c";

export default function PageSub02c1() {
  return (
    <div id="pageSub02c1">
      <TextExtraType1d></TextExtraType1d>
      <ListType1c></ListType1c>
    </div>
  );
}
