import React from "react";
import ButtonType1 from "./ButtonType1";
import TitleType1 from "./TitleType1";
export default function Terms() {
  return (
    <div className="report_type1">
         <h1 className="title_type2" style={{"fontWeight":"bold","marginBottom":"30px","textAlign":"center","fontSize":"20px"}}>DID기술융합공작소 사이트 약관확인 및 동의 내용</h1>
        <h2 style={{"fontWeight":"bold","fontSize":"18px"}}>서비스 이용약관</h2>
        <p style={{"whiteSpace":"pre-wrap"}}>본 약관은 홈페이지 서비스의 이용과 관련하여 홈페이지 서비스를 제공하는 따뜻한메이커연구소 주식회사 (이하 ‘당사’)와 이를 이용하는 홈페이지 서비스 회원(이하 ‘회원’)과의 관계를 설명합니다.

홈페이지 서비스를 이용하시거나 홈페이지 서비스 회원으로 가입하실 경우 여러분은 본 약관 및 관련 운영 정책을 확인하거나 동의하게 되므로, 잠시 시간을 내시어 주의 깊게 살펴봐 주시기 바랍니다.

당사는 기본적으로 여러분 모두에게 동일한 내용의 서비스를 제공합니다.
회원으로 가입을 하셔야만 홈페이지 서비스를 이용할 수 있습니다.
여러분은 본 약관을 읽고 동의하신 후 회원 가입을 신청하실 수 있으며, 당사는 이에 대한 승낙을 통해 회원 가입 절차를 완료하고 여러분께 홈페이지 서비스 이용 계정(이하 ‘계정’)을 부여합니다. 계정이란 회원이 홈페이지 서비스에 로그인한 이후 이용하는 각종 서비스 이용 이력을 회원 별로 관리하기 위해 설정한 회원 식별 단위를 말합니다.

여러분이 제공한 콘텐츠를 소중히 다룰 것입니다.
당사는 여러분이 게재한 게시물이 홈페이지 서비스를 통해 다른 이용자들에게 전달되어 우리 모두의 삶을 더욱 풍요롭게 해줄 것을 기대합니다.
게시물은 여러분이 타인 또는 자신이 보게 할 목적으로 홈페이지 서비스 상에 게재한 부호, 문자, 음성, 음향, 그림, 사진, 동영상, 링크 등으로 구성된 각종 콘텐츠 자체 또는 파일을 말합니다. 당사는 여러분의 생각과 감정이 표현된 콘텐츠를 소중히 보호할 것을 약속 드립니다.
여러분이 제작하여 게재한 게시물에 대한 지식재산권 등의 권리는 당연히 여러분에게 있습니다.
한편, 홈페이지 서비스를 통해 여러분이 게재한 게시물을 적법하게 제공하려면 해당 콘텐츠에 대한 저장, 복제, 수정, 공중 송신, 전시, 배포, 2차적 저작물 작성(단, 번역에 한함) 등의 이용 권한(기한과 지역 제한에 정함이 없으며, 별도 대가 지급이 없는 라이선스)이 필요합니다.
게시물 게재로 여러분은 당사에게 그러한 권한을 부여하게 되므로, 여러분은 이에 필요한 권리를 보유하고 있어야 합니다.

당사는 여러분이 부여해 주신 콘텐츠 이용 권한을 저작권법 등 관련 법령에서 정하는 바에 따라 홈페이지 서비스 내 노출, 서비스 홍보를 위한 활용, 서비스 운영, 개선 및 새로운 서비스 개발을 위한 연구, 웹 접근성 등 법률상 의무 준수, 외부 사이트에서의 검색, 수집 및 링크 허용을 위해서만 제한적으로 행사할 것입니다. 만약, 그 밖의 목적을 위해 부득이 여러분의 콘텐츠를 이용하고자 할 경우엔 사전에 여러분께 설명을 드리고 동의를 받도록 하겠습니다.

또한 여러분이 제공한 소중한 콘텐츠는 홈페이지 서비스를 개선하고 새로운 서비스를 제공하기 위해 인공지능 분야 기술 등의 연구 개발 목적으로 당사 및 당사 계열사에서 사용될 수 있습니다. 당사는 지속적인 연구 개발을 통해 여러분께 좀 더 편리하고 유용한 서비스를 제공해 드릴 수 있도록 최선을 다하겠습니다.

당사는 여러분의 개인정보를 소중히 보호합니다.
당사는 서비스의 원활한 제공을 위하여 회원이 동의한 목적과 범위 내에서만 개인정보를 수집∙이용하며, 개인정보 보호 관련 법령에 따라 안전하게 관리합니다.

타인의 권리를 존중해 주세요.
여러분이 무심코 게재한 게시물로 인해 타인의 저작권이 침해되거나 명예훼손 등 권리 침해가 발생할 수 있습니다.

홈페이지 서비스를 통해 타인의 콘텐츠를 이용한다고 하여 여러분이 해당 콘텐츠에 대한 지식재산권을 보유하게 되는 것은 아닙니다.
여러분이 해당 콘텐츠를 자유롭게 이용하기 위해서는 그 이용이 저작권법 등 관련 법률에 따라 허용되는 범위 내에 있거나, 해당 콘텐츠의 지식재산권자로부터 별도의 이용 허락을 받아야 하므로 각별한 주의가 필요합니다.

당사는 여러분이 홈페이지 서비스를 마음껏 이용할 수 있도록 여러분께 홈페이지 서비스에 수반되는 관련 소프트웨어 사용에 관한 이용 권한을 부여합니다. 이 경우 여러분의 자유로운 이용은 당사가 제시하는 이용 조건에 부합하는 범위 내에서만 허용되고, 이러한 권한은 양도가 불가능하며, 비독점적 조건 및 법적고지가 적용된다는 점을 유의해 주세요.

홈페이지 서비스 이용과 관련하여 몇 가지 주의사항이 있습니다.
당사는 여러분이 홈페이지 서비스를 자유롭고 편리하게 이용할 수 있도록 최선을 다하고 있습니다.
다만, 여러분이 홈페이지 서비스를 보다 안전하게 이용하고 홈페이지 서비스에서 여러분과 타인의 권리가 서로 존중되고 보호받으려면 여러분의 도움과 협조가 필요합니다.
여러분의 안전한 서비스 이용과 권리 보호를 위해 부득이 아래와 같은 경우 여러분의 게시물 게재나 홈페이지 서비스 이용이 제한될 수 있으므로, 이에 대한 확인 및 준수를 요청 드립니다. 회원 가입 시 이름, 생년월일, 휴대전화번호 등의 정보를 허위로 기재해서는 안 됩니다.
회원 계정에 등록된 정보는 항상 정확한 최신 정보가 유지될 수 있도록 관리해 주세요.
자신의 계정을 다른 사람에게 판매, 양도, 대여 또는 담보로 제공하거나 다른 사람에게 그 사용을 허락해서는 안 됩니다.
아울러 자신의 계정이 아닌 타인의 계정을 무단으로 사용해서는 안 됩니다.
타인에 대해 직접적이고 명백한 신체적 위협을 가하는 내용의 게시물, 타인의 자해 행위 또는 자살을 부추기거나 권장하는 내용의 게시물, 타인의 신상정보, 사생활 등 비공개 개인정보를 드러내는 내용의 게시물, 타인을 지속적으로 따돌리거나 괴롭히는 내용의 게시물, 성매매를 제안, 알선, 유인 또는 강요하는 내용의 게시물, 공공 안전에 대해 직접적이고 심각한 위협을 가하는 내용의 게시물은 제한될 수 있습니다.
관련 법령상 금지되거나 형사처벌의 대상이 되는 행위를 수행하거나 이를 교사 또는 방조하는 등의 범죄 관련 직접적인 위험이 확인된 게시물, 관련 법령에서 홍보, 광고, 판매 등을 금지하고 있는 물건 또는 서비스를 홍보, 광고, 판매하는 내용의 게시물, 타인의 지식재산권 등을 침해하거나 모욕, 사생활 침해 또는 명예훼손 등 타인의 권리를 침해하는 내용이 확인된 게시물은 제한될 수 있습니다.
자극적이고 노골적인 성행위를 묘사하는 등 타인에게 성적 수치심을 유발시키거나 왜곡된 성 의식 등을 야기할 수 있는 내용의 게시물, 타인에게 잔혹감 또는 혐오감을 일으킬 수 있는 폭력적이고 자극적인 내용의 게시물, 본인 이외의 자를 사칭하거나 허위사실을 주장하는 등 타인을 기만하는 내용의 게시물, 과도한 욕설, 비속어 등을 계속하여 반복적으로 사용하여 심한 혐오감 또는 불쾌감을 일으키는 내용의 게시물은 제한될 수 있습니다.
자동화된 수단을 활용하는 등 홈페이지 서비스의 기능을 비정상적으로 이용하여 게재된 게시물, 홈페이지 서비스의 제공 취지와 부합하지 않는 내용의 게시물은 다른 이용자들의 정상적인 홈페이지 서비스 이용에 불편을 초래하고 더 나아가 당사의 원활한 서비스 제공을 방해하므로 역시 제한될 수 있습니다.

당사의 사전 허락 없이 자동화된 수단(예: 매크로 프로그램, 로봇(봇), 스파이더, 스크래퍼 등)을 이용하여 홈페이지 서비스 회원으로 가입을 시도 또는 가입하거나, 홈페이지 서비스에 로그인을 시도 또는 로그인하거나, 홈페이지 서비스 상에 게시물을 게재하거나, 홈페이지 서비스를 통해 커뮤니케이션하거나(예: 전자메일, 쪽지 등), 홈페이지 서비스에 게재된 회원의 아이디(ID), 게시물 등을 수집하거나, 이용자(사람)의 실제 이용을 전제로 하는 홈페이지 서비스의 제공 취지에 부합하지 않는 방식으로 홈페이지 서비스를 이용하거나 이와 같은 홈페이지 서비스에 대한 어뷰징(남용) 행위를 막기 위한 당사의 기술적 조치를 무력화하려는 일체의 행위(예: IP를 지속적으로 바꿔가며 접속하는 행위, Captcha를 외부 솔루션 등을 통해 우회하거나 무력화 하는 행위 등)를 시도해서는 안 됩니다.

당사의 동의 없이 자동화된 수단에 의해 홈페이지 서비스 상에 광고가 게재되는 영역 또는 그 밖의 영역에 부호, 문자, 음성, 음향, 그림, 사진, 동영상, 링크 등으로 구성된 각종 콘텐츠 자체 또는 파일을 삽입해서는 안 됩니다. 또한, 홈페이지 서비스 또는 이에 포함된 소프트웨어를 복사, 수정할 수 없으며, 이를 판매, 양도, 대여 또는 담보로 제공하거나 타인에게 그 이용을 허락해서는 안 됩니다. 홈페이지 서비스에 포함된 소프트웨어를 역 설계, 소스코드 추출 시도, 복제, 분해, 모방, 기타 변형하는 등의 행위도 금지됩니다(다만, 오픈소스에 해당되는 경우 그 자체 조건에 따릅니다).
그 밖에 바이러스나 기타 악성 코드를 업로드하거나 홈페이지 서비스의 원활한 운영을 방해할 목적으로 서비스 기능을 비정상적으로 이용하는 행위 역시 금지됩니다.

부득이 서비스 이용을 제한할 경우 합리적인 절차를 준수합니다.
당사는 다양한 정보와 의견이 담긴 여러분의 콘텐츠를 소중히 다룰 것을 약속 드립니다만, 여러분이 게재한 게시물이 관련 법령, 본 약관, 게시물 운영정책, 각 개별 서비스에서의 약관, 운영정책 등에 위배되는 경우, 부득이 이를 비공개 또는 삭제 처리하거나 게재를 거부할 수 있습니다. 다만, 이것이 당사가 모든 콘텐츠를 검토할 의무가 있다는 것을 의미하지는 않습니다.

또한 여러분이 관련 법령, 본 약관, 계정 및 게시물 운영정책, 각 개별 서비스에서의 약관, 운영정책 등을 준수하지 않을 경우, 당사는 여러분의 관련 행위 내용을 확인할 수 있으며, 그 확인 결과에 따라 홈페이지 서비스 이용에 대한 주의를 당부하거나, 홈페이지 서비스 이용을 정지시키는 등 그 이용을 제한할 수 있습니다. 한편, 이러한 이용 제한에도 불구하고 더 이상 홈페이지 서비스 이용계약의 온전한 유지를 기대하기 어려운 경우엔 부득이 여러분과의 이용계약을 해지할 수 있습니다.

당사의 잘못은 당사가 책임집니다.
당사는 여러분이 홈페이지 서비스를 이용함에 있어 당사의 고의 또는 과실로 인하여 손해를 입게 될 경우 관련 법령에 따라 여러분의 손해를 배상합니다.
다만, 천재지변 또는 이에 준하는 불가항력으로 인하여 당사가 서비스를 제공할 수 없거나 이용자의 고의 또는 과실로 인하여 서비스를 이용할 수 없어 발생한 손해에 대해서 당사는 책임을 부담하지 않습니다.
그리고 당사가 손해배상책임을 부담하는 경우에도 통상적으로 예견이 불가능하거나 특별한 사정으로 인한 특별 손해 또는 간접 손해, 기타 징벌적 손해에 대해서는 관련 법령에 특별한 규정이 없는 한 책임을 부담하지 않습니다.
한편, 홈페이지 서비스를 매개로 한 여러분과 다른 회원 간의 의견 교환 등에서 발생한 손해나 여러분이 서비스 상에 게재된 타인의 게시물 등의 콘텐츠를 신뢰함으로써 발생한 손해에 대해서도 당사는 특별한 사정이 없는 한 이에 대해 책임을 부담하지 않습니다.

서비스 중단 또는 변경 시 꼭 알려드리겠습니다.
컴퓨터, 서버 등 정보통신설비의 보수점검, 교체 또는 고장, 통신두절 등 운영상 상당한 이유가 있는 경우 부득이 서비스의 전부 또는 일부를 중단할 수 있습니다.
한편, 당사는 서비스 운영 또는 개선을 위해 상당한 필요성이 있는 경우 서비스의 전부 또는 일부를 수정, 변경 또는 종료할 수 있습니다.
무료로 제공되는 서비스의 전부 또는 일부를 수정, 변경 또는 종료하게 된 경우 관련 법령에 특별한 규정이 없는 한 별도의 보상을 하지 않습니다.
이 경우 당사는 예측 가능한 경우 상당기간 전에 이를 안내하며, 만약 예측 불가능한 경우라면 사후 지체 없이 상세히 설명하고 안내 드리겠습니다.

주요 사항을 잘 안내하고 여러분의 소중한 의견에 귀 기울이겠습니다.
당사는 서비스 이용에 필요한 주요사항을 적시에 잘 안내해 드릴 수 있도록 힘쓰겠습니다.
회원에게 통지를 하는 경우 전자메일 또는 기타 적절한 전자적 수단을 통해 개별적으로 알려 드릴 것이며, 다만 회원 전체에 대한 통지가 필요할 경우엔 초기 화면 또는 공지사항 등에 관련 내용을 게시하도록 하겠습니다.

여러분이 쉽게 알 수 있도록 약관 및 운영정책을 게시하며 사전 공지 후 개정합니다.
당사는 수시로 본 약관, 계정 및 게시물 운영정책을 개정할 수 있습니다만, 관련 법령을 위배하지 않는 범위 내에서 개정할 것이며, 사전에 그 개정 이유와 적용 일자를 서비스 내에 알리도록 하겠습니다.

본 약관은 한국어를 정본으로 합니다. 본 약관 또는 홈페이지 서비스와 관련된 여러분과 당사와의 관계에는 대한민국의 법령이 적용됩니다. 그리고 본 약관 또는 홈페이지 서비스와 관련하여 여러분과 당사 사이에 분쟁이 발생할 경우, 그 분쟁의 처리는 대한민국 '민사소송법'에서 정한 절차를 따릅니다.
</p>
<h2 style={{"fontWeight":"bold","fontSize":"18px"}}>서비스 이용약관</h2>
개인정보 수집 및 이용에 대한 안내
<p style={{"whiteSpace":"pre-wrap"}}>
정보통신망법 규정에 따라 서비스에 회원가입 신청하시는 분께 수집하는 개인정보의 항목, 개인정보의 수집 및 이용목적, 개인정보의 보유 및 이용기간을 안내 드리오니 자세히 읽은 후 동의하여 주시기 바랍니다.

1. 수집하는 개인정보
이용자는 회원가입을 하는 경우에만 서비스를 이용할 수 있으며 서비스를 이용하기 위해 회원가입을 할 경우, 당사는 서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다.
회원가입 시점에 당사가 이용자로부터 수집하는 개인정보는 아래와 같습니다.
회원 가입 시에 ‘성명, 이메일 주소, 연락처, 생년월일, 거주지 주소, 소속’을필수항목으로 수집합니다.

서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기정보, 위치정보가 생성되어 수집될 수 있습니다. 구체적으로

1) 서비스 이용 과정에서 이용자에 관한 정보를 정보통신서비스 제공자가 자동화된 방법으로 생성하여 이를 저장(수집)하거나,
2) 이용자 기기의 고유한 정보를 원래의 값을 확인하지 못하도록 안전하게 변환한 후에 수집하는 경우를 의미합니다.
2. 수집한 개인정보의 이용
서비스(모바일 웹/앱 포함)의 회원관리, 서비스 개발・제공 및 향상, 안전한 인터넷 이용환경 구축 등 아래의 목적으로만 개인정보를 이용합니다.

회원 가입 의사의 확인, 이용자 식별 등 회원관리를 위하여 개인정보를 이용합니다.
서비스 방문 및 이용기록의 분석과 신규 서비스 요소의 발굴 및 기존 서비스 개선 등을 위하여 개인정보를 이용합니다.
법령 및 당사 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 약관 개정 등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다.
상품 및 서비스의 배송을 위하여 개인정보를 이용합니다.
이벤트 정보 및 참여기회 제공, 마케팅 및 프로모션 목적으로 개인정보를 이용합니다.
서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등에 개인정보를 이용합니다.
보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축을 위해 개인정보를 이용합니다.
3. 개인정보의 파기
개인정보의 수집 및 이용목적이 달성된 개인정보는 재생이 불가능한 방법으로 파기하고 있습니다. 법령에서 보존의무를 부과한 정보에 대해서도 해당 기간 경과 후 지체없이 재생이 불가능한 방법으로 파기합니다. 전자적 파일 형태의 경우 복구 및 재생이 되지 않도록 기술적인 방법을 이용하여 안전하게 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식 등으로 파기합니다.
</p>
<h2 style={{"fontWeight":"bold","fontSize":"18px"}}>서비스 이용약관</h2>
<p style={{"whiteSpace":"pre-wrap"}}>
【초상권 사용 동의서】
정보화 사회로 홈페이지 및 블로그, SNS 등 다양한 정보를 공유함에 따라 개인정보 보호 정책으로 {`<DID기술융합공작소>`} 활동시 촬영된 사진 및 동영상에 관하여 초상권에 대한 동의를 받고자 합니다.<br/>

▣ 사용 기관 : DID기술융합공작소, 따뜻한메이커연구소 주식회사<br/>
▣ 사용 매체명 : 소셜미디어매체, 웹사이트, 홍보물, 기사, 보고서<br/>
▣ 사용 형태 : DID기술융합공작소 교육 또는 행사 중 찍은 사진 또는 동영상 자료<br/>
▣ 사용 목적 : 내 외부 홍보 및 내부 보고용<br/>
▣ 초상권 사용료 : 없음<br/>
위의 사항에 따라 초상권 사용에 동의하며, 그 권리를 위 기관에 위임합니다.
</p>

    </div>
  );
}
