import React from "react";
import Vision from "../../contents/vision";

export default function PageSub01b2() {
  return (
    <div id="pageSub01b2">
      <div>
        <Vision></Vision>
      </div>
    </div>
  );
}
