export const DataList = [
            {
                id: 1,
                value: "소재지",
                a: "세종특별자치시 국세청로 4(나성동, 갤러리 세종프라자)",
                b: "대전 중구 중앙로 101 (선화동)",
               c: "대전 유성구 테크노4로 17 (관평동)",
                d: "대전 유성구 가정로 218 (가정동, 한국전자통신연구원)",
              e: "서울특별시 관악구 난곡로 327-1, 비1층(신림동)",
                f: "대전광역시 유성구 가정로 218, 1층(가정동)",
                g:"인천 서구 건지로121번길 33-12 (석남동)"
            },
            {
                id: 2,
                value: "대표담당자",
                a: "이재기실장",
                b: "이광민대표",
                 c: "김진현대표",
                 d: "이주형책임",
                e: "손성창",
                f: "김명석대표",
                g:"디엠비"
            },
            {
                id: 3,
                value: "주요 지원분야",
                a: "서비스",
               b: "3D목업 설계,목자재 가공",
                c: "로봇",
                d: "3D프린터",
               e: "영상",
               f: "제조업",
               g:"산업디자인"
            },
            {
                id: 4,
                value: "등록일",
                a: "2020-10-07",
                b: "2020-10-07",
                c: "2020-10-07",
                d: "2020-10-07",
                e: "2020-10-07",
               f: "2020-10-07",
               g:"2020-10-07"
                
            },
            {
                id: 5,
                value: "전화번호",
                a: "000-000-0000",
               b: "042-385-4204",
                c: "042-933-3369",
                d: "042-385-4207",
               e: "042-385-4201",
               f: "042-860-4806",
               g:"070-7720-1215"
            }
        ]
