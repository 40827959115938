import React from "react";
import ListType2a from "../../contents/ListType2a";

export default function PageSub05a1() {
  return (
    <div id="pageSub05a1">
      <ListType2a></ListType2a>
    </div>
  );
}
