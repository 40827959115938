import React from "react";
import ListType2d from "../../contents/ListType2d";

export default function PageSub07b3() {
  return (
    <div id="pageSub07b3">
      <ListType2d></ListType2d>
    </div>
  );
}
