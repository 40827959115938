import React from "react";
import InfoType3b from "../../contents/InfoType3b";

export default function PageSub07b4() {
  return (
    <div id="pageSub07b4">
      <InfoType3b></InfoType3b>
    </div>
  );
}
