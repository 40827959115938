import React from "react";
import ReportType1a from "../../contents/ReportType1a";

export default function PageSub06d5() {
  return (
    <div id="pageSub06d5">
      <ReportType1a></ReportType1a>
    </div>
  );
}
