import React from "react";
import TableType2b from "../../contents/TableType2b";
import TextExtraType1a from "../../contents/TextExtraType1a";

export default function PageSub02a7() {
  return (
    <div id="pageSub02a7">
      <TextExtraType1a></TextExtraType1a>
      <TableType2b></TableType2b>
    </div>
  );
}
