import React from "react";
import ListType2e from "../../contents/ListType2e";

export default function PageSub07b5() {
  return (
    <div id="pageSub07b5">
      <ListType2e></ListType2e>
    </div>
  );
}
