import React, { useEffect } from "react";
import Map from "./Maps";

export default function PageSub01c() {
  return (
    <div id="pageSub01c">
      <Map/>
    </div>
  );
}
