import React from "react";
import ButtonType1 from "./ButtonType1";
import TitleType1 from "./TitleType1";
export default function CopyRight() {
  return (
    <div className="report_type1">
      <img src="/images/jujak.png" alt="no" style={{position:"absolute","left":"200px",top:"150px"}}/>
    </div>
    );
}
