import React from "react";
import TableType1d from "../../contents/TableType1d";

export default function PageSub01d() {
  return (
    <div id="pageSub01d">
      <TableType1d></TableType1d>
    </div>
  );
}
