import React from "react";
export default function ListType1b() {
  return (
    <div className="list_type1">
      <ol>
        <li>
            <h1>o</h1>
        </li>
      </ol>
    </div>
  );
}
