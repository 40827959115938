import React from "react";
import TableType3a from "../../contents/TableType3a";

export default function PageSub07a() {
  return (
    <div id="pageSub07a">
      <TableType3a></TableType3a>
    </div>
  );
}
