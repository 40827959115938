import React from "react";
import TableType5a from "../../contents/TableType5a";

export default function PageSub04a1() {
  return (
    <div id="pageSub04a1">
      <TableType5a></TableType5a>
    </div>
  );
}
